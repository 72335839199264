
import {get,post} from "@/axios";

//用户页面
export function getUserList(data) {
    return post('/api/pvAccount/list',data);
}
export function addUser(data) {
    return post('/api/pvAccount/add',data);
}
export function editUser(data) {
    console.log(data,'接收到的数据')
    return post('/api/pvAccount/edit',data);
}
